<template>
  <div class="share">
    <div class="logo">
      <img src="~@assets/image/recommend_logo.png" alt="" />
    </div>
    <div class="content">
       <button class="btn" @click="download">下载写手领取</button>
      <div class="CRcode">
        <img src="~@assets/image/share_CR.png" alt="" />
      </div>
    </div>
    <div class="text">推荐码：{{ shareId }}</div>
    <div class="bigbox" v-if="isshow == true" @click="close"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      shareId: '',
      isshow:false
    }
  },
  methods: {
    close(){
       this.isshow = false
    },
    download() {
      var u = navigator.userAgent
      var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 //android终端
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/g) //ios终端
      if (isAndroid) {
        window.location.href = 'https://a.app.qq.com/o/simple.jsp?pkgname=com.writemall.pad'
      }
      if (isiOS) {
        if(u.match(/MicroMessenger/g) == "MicroMessenger"){
           this.isshow = true
        }else{
          window.location.href = 'https://itunes.apple.com/app/id1547262283'  //在微信浏览器不能用  只能用苹果自带的浏览器才能用
        }
      }
      return false
    },
  },
  created() {
    this.shareId = this.$route.query.id
  },
}
</script>

<style lang="scss" scoped>
@media screen  and (max-width:1000px){
  .bigbox{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: url("../assets/image/openBrowser.png") no-repeat;
    background-size: 100% 100%;
    z-index: 99;
  }
  .share {
  height: 100vh;
  background: url('~@assets/image/share_background.png') no-repeat;
  background-size: 100% 100%;
  overflow: auto;
  .logo {
    width: 89px;
    height: 24.5px;
    img {
      width: 100%;
      height: 100%;
      margin-top: 15px;
      margin-left: 20px;
    }
  }
  .content{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 418px;
     .btn {
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #302b33;
        width: 200px;
        height: 40px;
        background: linear-gradient(36deg, #e1c392, #ffeccd, #e4c89b);
        border-radius: 40px;
        text-align: center;
        border: none;
        line-height: 40px;
      }
      .CRcode {
        width: 88px;
        height: 88px;
        background: #ffeccd;
        text-align: center;
        margin-top: 20px;
        img {
          height: 79px;
          width: 79px;
          margin-top: 4.5px;
        }
      }
  }
  
  .text {
    font-family: PingFang SC;
    font-weight: bold;
    color: #ffecce;
    font-size: 15px;
    text-align: center;
    margin-top: 20px;
  }
}
}
@media screen  and (min-width:1000px) and (max-width:1280px){
    .share {
  height: 100vh;
  background: url('~@assets/image/share_background.png') no-repeat;
  background-size: 100% 100%;
  overflow: auto;
  .logo {
    width: 89px;
    height: 24.5px;
    img {
      width: 100%;
      height: 100%;
      margin-top: 15px;
      margin-left: 20px;
    }
  }
  .content{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 180px;
     .btn {
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #302b33;
        width: 200px;
        height: 40px;
        background: linear-gradient(36deg, #e1c392, #ffeccd, #e4c89b);
        border-radius: 40px;
        text-align: center;
        border: none;
        line-height: 40px;
      }
      .CRcode {
        width: 88px;
        height: 88px;
        background: #ffeccd;
        text-align: center;
        margin-top: 20px;
        img {
          height: 79px;
          width: 79px;
          margin-top: 4.5px;
        }
      }
  }
  
  .text {
    font-family: PingFang SC;
    font-weight: bold;
    color: #ffecce;
    font-size: 15px;
    text-align: center;
    margin-top: 20px;
  }
}
}
</style>
